import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';

export default function ContactPage() {

  const [name,setName] =useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [projecttype, setProjecttype] = useState('');
  const [description, setDescription] = useState('');


  
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form method='post' action='/success' name="contact" className="row">
            <input type="hidden" name="form-name" value="contact" />

              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" name="name" value={name} className="cs-form_field"
                onChange={(e) => setName(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" name="email" value={email} className="cs-form_field"
                  onChange={(e) =>setEmail(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" name="ProjectName"  value={projecttype} onChange={(e) =>setProjecttype(e.target.value)} className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" name="phonenumber"  value={phonenumber} onChange={(e) =>setPhonenumber(e.target.value)} className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Details of your project*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="description"
                  type="text"
                  value={description}
                  onChange={(e) =>setDescription(e.target.value)}
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type='submit' className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="60" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1237.7612434059893!2d-0.26556769999999996!3d51.650269550000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487616660ce825b7%3A0xe0ca7c21d2450d96!2sArundel%20Dr%2C%20Borehamwood%20WD6%202LE!5e0!3m2!1sen!2suk!4v1710588116389!5m2!1sen!2suk"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
