import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'Information We Collect',
    answer:
    `Personal Information: When you use our website or services, we may collect personal information such as your name, email address, phone number, and company details.
    Usage Information: We automatically collect information about your interaction with our website and services, including IP address, browser type, pages visited, and the duration of your visit.
    Cookies: We use cookies and similar tracking technologies to enhance your experience and analyze usage patterns on our website.
    
      `
  },
  {
    question: 'How We Use Your Information:',
    answer: `
    Providing Services: We may use your personal information to provide and improve our services, communicate with you, and fulfill your requests.
    Analytics: We use collected data to analyze trends, administer the website, and gather demographic information for aggregate use.
    Marketing: With your consent, we may send you promotional materials and offers about our services or third-party products we believe may be of interest to you.
    Legal Compliance: We may use your information to comply with applicable laws, regulations, or legal processes.
    `
  },
  {
    question: 'Information Sharing',
    answer:
    `Third-Party Service Providers: We may share your information with third-party service providers who assist us in operating our website or providing services to you.
    Legal Obligations: We may disclose your information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
    Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.
    
      `
  },
  {
    question: ' Data Security',
    answer:
    `4.1 Accountability for Damages: In this part, we detail the realm of potential accountability. We emphasize that Baheer LTD or its suppliers will not be held accountable for any damages that may arise from using or being unable to use the materials on the website. The subsequent discussion highlights instances where these limitations may or may not apply based on jurisdiction.

      4.2 Liability Limitations: This segment explores the scope of limitations concerning Baheer LTD's accountability for potential damages. The interplay of implied warranties and liability caps is scrutinized, providing a comprehensive view of the company's liability stance.
      
      4.3 Applicability of Jurisdiction: Here, we navigate the potential variations in jurisdictional laws and their impact on the enforceability of liability limitations. This provides you with insights into how legal contexts can influence the scope of the company's liability. `
  },
  {
    question: 'Revisions and Errata',
    answer:
    `We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction.
    Despite our efforts, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security of your information.`
  },
  {
    question: 'Your Rights',
    answer:
    `Access and Correction: You have the right to access and correct your personal information held by us.
    Opt-Out: You may opt-out of receiving promotional communications from us by following the instructions in those communications.
    Data Portability: Upon request, we will provide you with a copy of your personal data in a structured, commonly used, and machine-readable format.`
  },
  {
    question: "Children's Privacy",
    answer:
    `Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child under 13 has provided us with personal information, please contact us immediately.`
  },

  {
    question: ' Changes to this Privacy Policy',
    answer:
    `We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page with a revised "Effective Date" at the top.`
  },
  {
    question: 'Contact Us',
    answer:
    `If you have any questions or concerns about our Privacy Policy or practices, please contact us at info@Baheer.uk.

    By using our website or services, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our website or services.
    
    Last updated: [20-March-2024]`
  }, 
];

export default function Accordion3() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
