import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'What services does Baheer offer as a software development agency?',
    answer:
      'Baheer specializes in a wide array of services, including web app development, mobile app development, integration and migration, testing and QA, staff augmentation, digital product design, product maintenance, and DevOps solutions. Each service is tailored to meet specific client needs, ensuring top-notch quality and innovation.',
  },
  {
    question: 'How does Baheer approach software development projects?',
    answer:
      'At Baheer, we follow a customer-focused Agile methodology that emphasizes collaboration, flexibility, and continuous improvement. Our seven-step process includes Inception, Planning, Design, Development, Testing, Deployment, and Maintenance, ensuring a seamless and iterative development journey for our clients.',
  },
  {
    question: 'What engagement models does Baheer offer to its clients?',
    answer:
      'Baheer offers three adaptable engagement models: The Fixed Price model, perfect for Minimum Viable Products (MVPs); the Hourly Engagement model, ideal for product maintenance; and the Staff Augmentation model, tailored for long-term business partnerships. These models provide flexibility and scalability to meet various project needs.',
  },
  {
    question: 'How does Baheer ensure the timely delivery of projects without compromising quality?',
    answer:
      'Baheer prioritizes timely project delivery and quality assurance by employing skilled professionals, rigorous testing methodologies, and real-time client collaboration. Our Agile approach enables us to adapt swiftly to changes, ensuring projects are completed on time and within budget, meeting the highest quality standards.',
  },
  {
    question: 'How does Baheer prioritize data security and user privacy in its solutions?',
    answer:
      'Baheer prioritizes data security and user privacy through advanced encryption, secure authentication methods, and regular security audits. We adhere tostrict data protection laws and industry standards, safeguarding sensitive user information and ensuring client and user trust.',
  },
];

export default function Accordion() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
