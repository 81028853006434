import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'Agreement to Terms',
    answer:
    `1.1 Introduction to the Terms of Use: In this section, we introduce you to the essence of these Terms of Use, establishing a legally binding agreement between you and Baheer. It outlines the specific terms governing your interaction with the website, highlighting the pivotal aspects that regulate your access, browsing, and usage of the content present on www.Baheer.uk.

      1.2 Binding Agreement with Baheer: Delve deeper into the concept of this binding agreement. We elucidate how these terms extend to encompass your engagement with all textual content, data, software, and visual elements that we make accessible to you. This section underlines the significance of acknowledging and adhering to these terms.
      
      1.3 Use of the Website and Content: Gain a comprehensive understanding of the breadth of content covered by this agreement. We elaborate on how these terms apply to every facet of content offered on the website, shaping your experience and interactions
      
      1.4 Acceptance of Terms: In this segment, we emphasize the importance of your conscious choice to accept and abide by these terms. By browsing the website, you implicitly convey your understanding, agreement, and acceptance of these terms, creating a foundation for your continued use of the website.
      
      `
  },
  {
    question: 'Use License',
    answer: `
    2.1 Permission to Access and View: Unpack the granted permission that allows you to temporarily download a single copy of materials from Baheer LTD's Website. This license is exclusively intended for personal and non-commercial viewing, enabling you to engage with the content on the website within defined boundaries.

    2.2 Restrictions on Usage: Delve into the intricacies of your obligations under this license. Understand the limitations in place, such as restrictions on modifying, copying, or using the materials for any commercial purposes or public displays.
    
    2.3 Termination of License: Explore the outcomes of license violations. This section elaborates on the scenarios that could lead to the termination of the granted license, resulting in the cessation of your viewing rights and the necessity to eliminate any downloaded materials.
    
    `
  },
  {
    question: 'Disclaimer',
    answer:
    `3.1 Material Presentation: Here, we expound on the presentation of materials on Baheer LTD's Website. The term "as is" underlines that these materials are offered without any warranties, whether expressed or implied, setting the tone for the disclaimers and representations discussed further.

      3.2 Warranty Disclaimers: This section delves into the specifics of warranty disclaimers, clarifying that Baheer LTD does not offer any warranties regarding the accuracy, reliability, or outcomes of using the materials present on its Website. This provides a clear picture of the risk associated with relying solely on the content.
      
      3.3 Accuracy and Reliability: Gain insights into the potential nuances surrounding the accuracy and reliability of the materials. While efforts are made to provide accurate content, the dynamic nature of the online environment can result in variations and discrepancies.
      
      `
  },
  {
    question: 'Limitations',
    answer:
    `4.1 Accountability for Damages: In this part, we detail the realm of potential accountability. We emphasize that Baheer LTD or its suppliers will not be held accountable for any damages that may arise from using or being unable to use the materials on the website. The subsequent discussion highlights instances where these limitations may or may not apply based on jurisdiction.

      4.2 Liability Limitations: This segment explores the scope of limitations concerning Baheer LTD's accountability for potential damages. The interplay of implied warranties and liability caps is scrutinized, providing a comprehensive view of the company's liability stance.
      
      4.3 Applicability of Jurisdiction: Here, we navigate the potential variations in jurisdictional laws and their impact on the enforceability of liability limitations. This provides you with insights into how legal contexts can influence the scope of the company's liability. `
  },
  {
    question: 'Revisions and Errata',
    answer:
    `5.1 Material Accuracy and Updates:This section delves into the nature of the materials presented on Baheer LTD's Website. We underline that while efforts are made to ensure accuracy, these materials may occasionally contain technical, typographical, or photographic errors. This acknowledgment sets the stage for the subsequent discussion on updates and accuracy assurance.

      5.2 Technical and Typographical Errors: Understand how Baheer LTD addresses potential errors in materials. The company's commitment to maintaining the accuracy and relevancy of materials is expounded upon, offering insight into its approach to content curation.
      
      5.3 Material Accuracy and Updates: Recognize Baheer LTD's dedication to preserving material accuracy and relevancy. This commitment is substantiated by the company's prerogative to update materials, ensuring that users are presented with the most current and reliable information.`
  },
  {
    question: 'Trademarks',
    answer:
    ` 6.1 Baheer Trademarks: Delve into the realm of trademarks and branding. We discuss the trademark "Baheer" and associated logos and service marks that are registered trademarks owned by Baheer. This section highlights the significance of these trademarks as identifiers of the company's unique offerings and the importance of obtaining prior permission for their use.

    6.2 Trademark Protection: Explore the parameters set for the use and display of Baheer trademarks. We emphasize that unauthorized use, publication, or advertisement of these trademarks is prohibited without explicit permission from Baheer. This fosters a deeper understanding of the company's commitment to maintaining the integrity of its brand identity.
    
    6.3 Third-Party Trademarks: Acknowledge the presence of other product and company names mentioned on the site. We clarify that these names are likely trademarks or registered trademarks of their respective owners. While mentioned on the site, they do not imply affiliation or endorsement by Baheer. This section sheds light on the recognition of intellectual property belonging to others.`
  },
  {
    question: 'Link',
    answer:
    ` 7.1 Linked Websites: Uncover the dynamics of external links and their relationship to Baheer LTD's Website. We clarify that Baheer LTD has not reviewed all linked sites and disclaims responsibility for their content. This section offers insights into the user's responsibility when navigating linked sites and the significance of personal discretion.

    7.2 User's Own Risk: Delve into the implications of using linked websites. We highlight that the presence of a link does not equate to endorsement by Baheer LTD. This encourages users to exercise caution and evaluate the suitability of linked content, thus empowering informed decisions.`
  },

  {
    question: 'Site Terms of Use Modifications',
    answer:
    `8.1 Dynamic Nature of Terms: Understand the fluidity of the Terms of Use. BaheerLTD reserves the right to revise these terms without prior notice. This section addresses the company's prerogative to modify terms to align with changing circumstances, ensuring transparency and user awareness.

    8.2 Agreement to Updated Terms: Embrace the concept of agreement evolution. By using the website, users are impliedly agreeing to be bound by the most current version of these Terms and Conditions of Use. This demonstrates the importance of periodically revisiting the terms to stay informed and aligned.`
  },
  {
    question: 'Governing Law',
    answer:
    `10.1 Application of Local Laws: Gain insights into the regulatory framework that governs BaheerLTD's Website. We clarify that claims related to the website are subject to the laws of the applicable jurisdiction, underscoring the importance of complying with relevant legal requirements.

    10.2 Conflict of Law Provisions: Understand the implications of governing law in relation to potential conflicts. Regardless of potential differences in jurisdictional laws, these terms serve as a unified framework that governs user interaction with the website`
  }, 
];

export default function Accordion2() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
